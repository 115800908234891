




















































import Component, { mixins } from 'vue-class-component';
import RecommendationBadgeComponent from '@/components/RecommendationBadgeComponent.vue';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import EditFileResourceModal from '@/components/modals/EditFileResourceModal.vue';
import PillComponent from '@/components/pill/PillComponent.vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import ToastActionType from '@/utils/enums/ToastActionType';
import { namespace } from 'vuex-class';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';
import Speaker from '@/models/graphql/Speaker';
import ToastActionParams from '@/utils/types/ToastActionParams';
import DetailPageHeaderSaveHelper from '@/utils/helpers/DetailPageHeaderSaveHelper';
import FileResource from '@/models/graphql/FileResource';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

const fileResourceStore = namespace('FileResourceStore');
const toastStore = namespace('ToastStore');

@Component({
  data(): object {
    return {
      coverAvatar: '',
      newCoverAvatar: '',
      uploadedCoverAvatar: '',
      uploadingCover: false,
      coverPayloadTooLarge: false,
    };
  },
  components: {
    ButtonIconComponent,
    AvatarSoloWidget,
    RecommendationBadgeComponent,
    EditFileResourceModal,
    PillComponent,
    FontAwesomeComponent,
  },
})
/* eslint-disable no-underscore-dangle */
export default class SpeakerProfileAvatarWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @fileResourceStore.Action
  private createPhotoFileResourceForSpeaker!: (data: {
    uploadToken: string;
    speakerUid: string;
  }) => Promise<FileResource | undefined>;

  @fileResourceStore.Action
  private deleteFileResource!: (uid: string) => Promise<FileResource | undefined>;

  private speaker: Speaker | null = null;

  private FileResourceHelper = FileResourceHelper;

  private photoFileResource: FileResource | undefined | null = null;

  private get bannerSrc(): string {
    if (this.speaker && this.photoFileResource) {
      return FileResourceHelper.getFullPath(this.photoFileResource, 'w136');
    }
    return '';
  }

  onDeleteBannerImg(showToast = true): void {
    if (this.photoFileResource
        && this.photoFileResource.uid) {
      this.deleteFileResource(this.photoFileResource.uid);
      if (!this.$data.uploadingCover) {
        this.photoFileResource = undefined;
        if (this.authUser.speakers && this.authUser.speakers.length > 0) {
          this.authUser.speakers[0].photoFileResource = this.photoFileResource;
        }
        this.$data.newCoverAvatar = '';
        this.$bvModal.hide('edit-speaker-profile-cover');
      }
      if (showToast) {
        this.showToast(ToastActionType.REMOVE_AVATAR);
      }
    }
  }

  onLocalBannerSave(deleteBefore: boolean): void {
    this.$data.uploadingCover = true;
    this.$data.coverAvatar = (this.$refs.editorImageCover as EditFileResourceModal).showImage();
    if (this.community.code) {
      DetailPageHeaderSaveHelper.onSave(this.community.code,
        this.$data.coverAvatar,
        this.speaker ? `speaker_photo_${this.speaker.uid}.jpeg` : '')
        .then((request: XMLHttpRequest) => {
          request.onload = () => {
            if (request?.status === 413) {
              this.$data.coverPayloadTooLarge = true;
              this.$data.uploadingCover = false;
            } else {
              this.$data.coverPayloadTooLarge = false;
              if (deleteBefore) {
                this.onDeleteBannerImg(false);
              }
              const uploadToken = JSON.parse(request.response)?.uploadToken;
              if (uploadToken) {
                this.createPhotoFileResourceForSpeaker({
                  uploadToken,
                  speakerUid: this.speaker?.uid || '',
                })
                  .then((response) => {
                    if (response && response.uid) {
                      this.$data.newCoverAvatar = this.$data.coverAvatar;
                      this.photoFileResource = response;
                      if (this.authUser.speakers && this.authUser.speakers.length > 0) {
                        this.authUser.speakers[0].photoFileResource = this.photoFileResource;
                      }
                      this.$data.uploadingCover = false;
                      this.$bvModal.hide('edit-speaker-profile-cover');
                      this.showToast(ToastActionType.UPDATE_AVATAR);
                    } else {
                      this.$data.uploadingCover = false;
                      this.$bvModal.hide('edit-speaker-profile-cover');
                      this.showToast(ToastActionType.UPDATE_AVATAR_ERROR);
                    }
                  });
              }
            }
          };
        });
    }
  }

  created(): void {
    this.setDataConfig();
  }

  mounted(): void {
    if (this.authUser && this.authUser.speakers.length) {
      // eslint-disable-next-line prefer-destructuring
      this.speaker = this.authUser.speakers[0];
      this.photoFileResource = this.speaker.photoFileResource
        ? this.speaker.photoFileResource
        : undefined;
    }
  }

  private openModal(): void {
    this.$bvModal.show('edit-speaker-profile-cover');
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }
}
