var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReadyToDisplay)?_c('div',{class:['speaker-profile-avatar-widget', _vm.classList]},[_c('div',{staticClass:"speaker-cms-avatar d-flex align-items-center justify-content-center"},[_c('avatar-solo-widget',{staticClass:"mx-auto main-avatar",class:_vm.breakpoint.value,attrs:{"show-edit-button":true,"size":_vm.isMobile ? '96' : '136',"src":_vm.bannerSrc,"with-borders":true,"default-icon":"fa-regular fa-podium"},on:{"button-click":_vm.openModal}}),_c('div',{class:{
        'mobile': _vm.isMobile,
        'logo': true
      }},[_c('edit-file-resource-modal',{ref:"editorImageCover",attrs:{"camera-text":("" + (_vm.$t('app.my-profile.edit.modal.upload.button.use-device-camera'))),"cancel-text":("" + (_vm.$t('app.my-profile.edit.modal.image-cover.button.cancel'))),"current-bootstrap-breakpoint":_vm.breakpoint.value,"delete-text":("" + (_vm.$t('app.my-profile.edit.modal.image-cover.button.remove'))),"image":(_vm.speaker && _vm.photoFileResource)
          ? _vm.FileResourceHelper.getFullPath(_vm.photoFileResource, 'w1110')
          : (_vm.community.defaultUserBannerFileResource
            ? _vm.FileResourceHelper.getFullPath(_vm.community.defaultUserBannerFileResource, 'w1110')
            : ''),"is-portrait":true,"is-rounded":false,"is-square":true,"ok-text":("" + (_vm.$t('app.my-profile.edit.modal.image-cover.button.save'))),"optimal-dimensions":("" + (_vm.$t('file-resource-uploader.optimal-dimensions.speakers'))),"payload-too-large":_vm.coverPayloadTooLarge,"title":("" + (_vm.$t('app.my-profile.edit.modal.image-cover.title'))),"uploading":_vm.uploadingCover,"type":"edit-speaker-profile-cover"},on:{"on-add":_vm.onLocalBannerSave,"on-remove":_vm.onDeleteBannerImg}})],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }